import { AnalyticsConfig } from "@egds/react-core/utils";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { AnalyticsStore } from "bernie-plugin-mobx";

export const decorateDestinationHeroHtml = (text: string) => {
  const htmlWithStyle = text.replace(/<a/gi, '$& target="_blank"');

  return { __html: htmlWithStyle };
};

/* covered in e2e test */
/* istanbul ignore next line */
export const primaryButtonAnalytics = (moduleName: string, analytics?: AnalyticsStore): AnalyticsConfig => {
  return {
    id: moduleName,
    callback: (theId, description) => {
      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName: theId,
        action: Action.CLICK,
        linkName: description,
      };
      sendDelayedTrackEvent(flexTrackingInfo, analytics);
    },
  };
};

export enum Sources {
  Trover = "Trover",
  Wikimedia = "Wikimedia",
}

export enum RegionTypes {
  Neighborhood = "neighborhood",
}

export enum ImageHeight {
  ZERO_MIN_HEIGHT = "zero-min-height",
}
