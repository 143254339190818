import * as React from "react";
import { WizardHero } from "./views/WizardHero";
import { DestinationHeroGallery } from "./views/DestinationHeroGallery";
import { WizardHeroWithHeadings } from "./views/WizardHeroWithHeadings";
import { DestinationHeroProps } from "./typings";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { DestinationHeroFlexModuleResult } from "typings/microserviceModels/destination-hero-flex-module";
import { useClickstreamAnalytics, ITrackType } from "@shared-ui/clickstream-analytics-context";
import { destinationWizardImpressionMicroMessage } from "../../utility/analytics/DestinationWizardImpressionMicroMessage";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

export enum ModelView {
  Wizard = "wizard",
  Gallery = "gallery",
  WithHeadings = "withHeadings",
  WizardWithScrim = "wizardWithScrim",
  WizardWithCentralScrim = "wizardWithCentralScrim",
  SearchFormViewVrbo = "search-form-view-vrbo",
  SearchFormViewVrboWithCentralScrim = "search-form-view-vrbo-with-central-scrim",
  SearchFormViewWithBottomOverlap = "search-form-view-with-bottom-overlap",
}

export const DestinationHero = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: DestinationHeroProps) => {
    const { templateComponent, flexModuleModelStore, context } = props;
    const { track } = useClickstreamAnalytics();

    React.useEffect(() => {
      const gaiaId = content?.gaiaId;
      const imageId = content?.media?.[0]?.imageId;
      const algorithmId = content?.media?.[0]?.algorithmId;

      const wizardMicroMessages = gaiaId
        ? destinationWizardImpressionMicroMessage(context, gaiaId, imageId, algorithmId)
        : [];
      const microMessages = [
        {
          schemaName: "referrer",
          messageContent: {
            referrerId: `${context.searchContext.pageType}.DestinationHero.impression`,
            linkName: "destination-hero is viewed",
            eventType: "impression",
          },
        },
        ...wizardMicroMessages,
      ];

      track(ITrackType.EVENT, { data: microMessages });
    }, []);

    if (!templateComponent) {
      return null;
    }

    const model = flexModuleModelStore.getModel(templateComponent.metadata.id) as DestinationHeroFlexModuleResult;
    const content = model?.content;

    if (!model || model.empty || !content) {
      return null;
    }

    switch (model.view) {
      case ModelView.Gallery: {
        return <DestinationHeroGallery templateComponent={templateComponent} model={model} context={context} />;
      }
      case ModelView.WithHeadings: {
        return <WizardHeroWithHeadings templateComponent={templateComponent} model={model} context={context} />;
      }
      case ModelView.WizardWithScrim: {
        const smoothScrimGradient =
          "linear-gradient(180deg, rgba(12, 14, 28, 0) 1rem, rgba(12, 14, 28, 0.11) 5rem, rgba(12, 14, 28, 0.32) 10rem, rgba(12, 14, 28, 0.5) 15rem)";
        return (
          <WizardHero
            customGradient={smoothScrimGradient}
            templateComponent={templateComponent}
            model={model}
            context={context}
          />
        );
      }
      case ModelView.WizardWithCentralScrim:
      case ModelView.SearchFormViewVrboWithCentralScrim: {
        const mobileSmoothScrimCentralGradient =
          "linear-gradient(180deg, rgba(12, 14, 28, 0) 0%, rgba(12, 14, 28, 0.7) 15%, rgba(12, 14, 28, 0.6) 60%, rgba(12, 14, 28, 0) 100%)";
        const smoothScrimCentralGradient =
          "linear-gradient(180deg, rgba(12, 14, 28, 0) 10%, rgba(12, 14, 28, 0.6) 50%, rgba(12, 14, 28, 0.6) 60%, rgba(12, 14, 28, 0) 100%)";
        return (
          <Viewport>
            <ViewSmall>
              <WizardHero
                customGradient={mobileSmoothScrimCentralGradient}
                templateComponent={templateComponent}
                model={model}
                context={context}
              />
            </ViewSmall>
            <ViewMedium>
              <WizardHero
                templateComponent={templateComponent}
                customGradient={smoothScrimCentralGradient}
                model={model}
                context={context}
              />
            </ViewMedium>
          </Viewport>
        );
      }
      default: {
        return <WizardHero templateComponent={templateComponent} model={model} context={context} />;
      }
    }
  })
);

export default DestinationHero;
