import * as React from "react";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import GalleryCarousel from "components/shared/Gallery/GalleryCarousel/GalleryCarousel";
import GalleryMosaic from "components/shared/Gallery/GalleryMosaic/GalleryMosaic";
import { getAttributionAltText } from "components/shared/AttributionText/AttributionText";
import { DestinationHeroViewProps } from "../typings";
import { Media } from "typings/microserviceModels/destination-hero-flex-module";
import { useLocalization } from "@shared-ui/localization-context";
import { inject } from "mobx-react";
import { cropResizeSrc } from "src/components/shared/BlossomImage/BlossomImage";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { isVariantEnabled } from "src/components/utility/experiment";
import { imgDimension } from "../WizardHeroUtils";

export const DestinationHeroGallery = inject("analytics")((props: DestinationHeroViewProps) => {
  const { templateComponent, model, context } = props;
  /* istanbul ignore next: validation in parent component */
  if (!templateComponent) {
    return null;
  }
  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;
  const fmId = getFmId(templateComponent);
  const { content, imageHeight } = model;
  const { title, subTitle } = content;
  const isMobile = context && context.deviceInformation && context.deviceInformation.mobile;
  const imgStyle = {
    height: isMobile ? imgDimension(imageHeight) : undefined,
  };

  const imageGallery = () => {
    const { media } = content;
    if (!(media && media.length)) {
      return null;
    }

    const { formatText } = useLocalization();

    const images = media.map((image: Media) => {
      const { mediaUrl, mediaUrlOriginal, mediaAlt, mediaId, origin, source, author, authorUrl, license } = image;
      const mediaIdEligible = isVariantEnabled(context, "gallery_images_ML_relevancy_score_sorting");

      const dimension = isMobile ? { width: 360, height: 224 } : { width: 1040, height: 580 };
      const optimizedImageUrl = cropResizeSrc(mediaUrl, dimension, isMobile ? "low" : "high");

      const alt = getAttributionAltText(source, origin, title, mediaAlt, author, license, mediaUrlOriginal);

      return {
        alt: alt,
        src: optimizedImageUrl,
        source,
        origin,
        caption: mediaAlt || alt,
        thumbnailSrc: optimizedImageUrl,
        attribUrl: mediaUrlOriginal,
        authorName: author,
        authorUrl,
        licenseSuite: license?.suite,
        licenseName: license?.name,
        licenseUrl: license?.url,
        originalMediaUrl: mediaUrl,
        mediaId: mediaIdEligible ? mediaId : "",
      };
    });

    return (
      <Viewport>
        <ViewSmall>
          <GalleryCarousel
            analyticsId="TG.LP"
            analyticsModule="Dest"
            title={formatText("destinationImageGallery.thumbnails.title", title)}
            images={images}
            locationId={props.context.searchContext.location.id}
            imageStyle={imgStyle}
          />
        </ViewSmall>

        <ViewLarge>
          <GalleryMosaic
            analyticsId="TG.LP"
            analyticsModule="Dest"
            analytics={props.analytics}
            title={formatText("destinationImageGallery.title", title)}
            images={images}
            locationId={props.context.searchContext.location.id}
          />
        </ViewLarge>
      </Viewport>
    );
  };

  return (
    <div id={id} data-fm={fmId} data-fm-title-id={fmTitleId} className="DestinationHeroDx">
      <meta itemProp="name" content={subTitle ? `${title}, ${subTitle}` : title} />

      {content?.media?.length > 0 && <meta itemProp="image" content={content.media[0].mediaUrl} />}

      {imageGallery()}
    </div>
  );
});
