import * as React from "react";
import { inject } from "mobx-react";
import { decorateDestinationHeroHtml, ImageHeight, Sources } from "../DestinationHeroUtilities";
import { WizardHeroProps } from "../typings";
import { Media } from "typings/microserviceModels/destination-hero-flex-module";
import { useLocalization } from "@shared-ui/localization-context";
import { imgDimension, optimizeHeroImageUrl } from "components/flexComponents/DestinationHero/WizardHeroUtils";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { BlossomImage } from "components/shared/BlossomImage/BlossomImage";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText, EGDSTextProps } from "@egds/react-core/text";
import { EGDSScrim, EGDSScrimType } from "@egds/react-core/scrim";
import { ModelView } from "../DestinationHero";
import { useExperiment } from "@shared-ui/experiment-context";
import { useViewportMap, ViewSizes } from "@shared-ui/viewport-context";
import { DESTINATION_LANDING_PAGE_TYPES } from "../../WizardHotelPWA/utils/constants";

interface AtrributionTextProps {
  textAlignment: EGDSTextProps["align"];
  padding?: boolean;
}

const defaultGradient = "linear-gradient(rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56))";
const topGradient = "linear-gradient(rgba(12, 14, 28, 0) 0%, rgba(12, 14, 28, 0.5) 0%, rgba(12, 14, 28, 0) 22%)";

/**
 * This component is capable of handling the hero image in two different ways depending on the value of model.withAltAttribute flag
 *  1) renders plain <div> element of certain dimensions while leveraging its backgroundImage CSS property to set the hero image when
 *     model.withAltAttribute is false or undefined [default]
 *  2) renders <div> element along with <img> element while using the <div> as a parent container for <img> to set its size and <img>
 *     to set the hero image itself along with its text description when model.withAltAttribute is true
 *  All this was done in order to eliminate the gap in SEO between HCOM Classic and HOB landing pages by making hero image have the
 *  text description set via alt attribute. The reason why we keep both implementations and the "plain <div> with backgroundImage" approach
 *  is used by default instead of eliminating its use in favour of using <img>s everywhere is because they aren't fully interchangeable
 *  due to different positioning of html elements. In the default implementation, there's a single <div> element with static position while
 *  we have an absolutely-positioned <img> in the alternative implementation, so that makes them behave differently when WizardHero is used
 *  with any other non-statically positioned element within the same container. Updating the WizardHero for some template without checking
 *  to see if there's any other absolutely-positioned element within its parent-container and without verifying the order in which these
 *  components appear in the markup or whether z-indexes are set properly may result in a broken page layout, most likely - WizardHero obscuring
 *  another absolutely-positioned component (like SearchForm or DatePicker). So, to avoid risks of breaking the current layout of some pages,
 *  the alternative WizardHero implementation can be applied to different templates individually by setting withAltAttribute=true in flex-manager.
 * see: https://jira.expedia.biz/browse/CLDL-61
 * @param props
 */
export const WizardHero = inject("wizardState")((props: WizardHeroProps) => {
  const { templateComponent, model, context, wizardState, customGradient } = props;

  if (!templateComponent) {
    return null;
  }

  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;
  const fmId = getFmId(templateComponent);
  const {
    content,
    showBackgroundOverlay = true,
    view,
    withAltAttribute,
    imageHeight,
    attributionTextOnTop = false,
  } = model;
  const { formatText } = useLocalization();

  const firstImage = content && content.media && content.media[0];
  const fallbackImage: Media = {
    // Empty fields are required to adhere to our autogenerated typing for Media.
    cardIdentifier: "",
    mediaId: "",
    mediaUrl: "//media.expedia.com/media/content/shared/images/travelguides/sem-hotels-tablet.jpg",
    mediaUrlTrimmed: "",
    mediaUrlOriginal: "//media.expedia.com/media/content/shared/images/travelguides/sem-hotels-tablet.jpg",
    mediaAlt: "Default HTG hotel image",
    origin: "Expedia",
    source: "",
    author: "",
    authorUrl: "",
    license: { name: "", suite: "", url: "" },
    mediaType: "Image",
  };
  const selectedHeroImage =
    firstImage && !firstImage.mediaUrl.includes("TE_Clouds_card_r1.jpg") ? firstImage : fallbackImage;

  if (wizardState) {
    wizardState.globalWizardState.config.heroImageSrc = selectedHeroImage.mediaUrl;
  }
  const isViewVrbo = view === ModelView.SearchFormViewVrbo || view === ModelView.SearchFormViewVrboWithCentralScrim;
  const isMobile =
    context &&
    context.deviceInformation &&
    context.deviceInformation.mobile &&
    context.deviceInformation.type === "MOBILE_PHONE";
  const isDestinationLandingPage = context.searchContext.pageType
    ? DESTINATION_LANDING_PAGE_TYPES.has(context.searchContext.pageType)
    : false;
  //Using isSmallView to differentiate mWeb and Small view port of desktop browser
  const isSmallView = useViewportMap({ [ViewSizes.SMALL]: true, [ViewSizes.MEDIUM]: false });
  const { exposure: bexSearchOptimizationForDesktop } = useExperiment("Search_Form_UI_Optimisation_");
  const isBEXSearchOptimizationForDesktopVariant =
    bexSearchOptimizationForDesktop.bucket === 1 &&
    isDestinationLandingPage &&
    context?.site?.brand === "expedia" &&
    !isSmallView;
  const overlapView =
    view === ModelView.SearchFormViewWithBottomOverlap || isBEXSearchOptimizationForDesktopVariant
      ? ModelView.SearchFormViewWithBottomOverlap
      : "";
  const imageSource = optimizeHeroImageUrl(selectedHeroImage.mediaUrl, context?.deviceInformation?.mobile, isViewVrbo);
  const wizardHeroGradient =
    showBackgroundOverlay && !isBEXSearchOptimizationForDesktopVariant ? customGradient || defaultGradient : "";
  const imageSourceAttribute = withAltAttribute ? "" : `url('${imageSource}')`;

  const { source } = selectedHeroImage;
  const isTroverSource = source === Sources.Trover;
  const isWikimediaSource = source === Sources.Wikimedia;
  const isAttributionRequired = isTroverSource || isWikimediaSource;
  const attributionClass =
    isAttributionRequired &&
    view !== ModelView.SearchFormViewWithBottomOverlap &&
    !isBEXSearchOptimizationForDesktopVariant
      ? "withAttributionText"
      : "";
  const isAttributionTextExist = isAttributionRequired && selectedHeroImage?.author;
  const setTopGradiant = attributionTextOnTop && isAttributionTextExist ? topGradient : "";
  const backgroundImage = `${wizardHeroGradient}${setTopGradiant}${
    (wizardHeroGradient || setTopGradiant) && imageSourceAttribute ? ", " : ""
  }${imageSourceAttribute}`;
  const searchFormView = isViewVrbo ? "searchFormView" : "";
  const heroWithAltAttr = withAltAttribute ? "withAltAttr uitk-image" : "";
  const mainClassNames = `DestinationHero wizard ${attributionClass} ${searchFormView} ${overlapView} ${heroWithAltAttr}`;
  const isAbsoluteView = imageHeight === ImageHeight.ZERO_MIN_HEIGHT;

  const heroTitle = (): string => {
    if (!isWikimediaSource) {
      return "";
    }

    return formatText(
      "hero.attribution.wikimediaAlt",
      selectedHeroImage.mediaAlt,
      selectedHeroImage.author,
      selectedHeroImage.license && selectedHeroImage.license.name
    );
  };

  const AtrributionText: React.FC<AtrributionTextProps> = ({ textAlignment, padding = true }) => {
    const { mediaUrlOriginal, author, authorUrl, license } = selectedHeroImage;
    const licenseSuite = license && license.suite;
    const licenseUrl = license && license.url;

    if (!isAttributionRequired || !author || author === "") {
      return null;
    }

    const htmlToDisplay = () => {
      if (isTroverSource) {
        const troverPhotoAnchor = formatText("hero.attribution.troverLink", author, mediaUrlOriginal);

        return decorateDestinationHeroHtml(troverPhotoAnchor);
      }

      const wikimediaPhotoAnchor =
        !authorUrl || authorUrl === ""
          ? formatText("hero.attribution.wikimediaLinkPlainAuthor", mediaUrlOriginal, author, licenseUrl, licenseSuite)
          : formatText("hero.attribution.wikimediaLink", mediaUrlOriginal, authorUrl, author, licenseUrl, licenseSuite);

      return decorateDestinationHeroHtml(wikimediaPhotoAnchor);
    };

    return (
      <EGDSSpacing padding={{ blockstart: "one" }}>
        <EGDSText
          size={attributionTextOnTop ? 300 : 200}
          align={textAlignment}
          theme={attributionTextOnTop ? "light" : undefined}
        >
          <EGDSSpacing {...(padding && { padding: { block: "two", inline: "three" } })}>
            <span dangerouslySetInnerHTML={htmlToDisplay()} />
          </EGDSSpacing>
        </EGDSText>
      </EGDSSpacing>
    );
  };

  const imgStyle = {
    minHeight: isMobile && view !== ModelView.SearchFormViewWithBottomOverlap ? imgDimension(imageHeight) : undefined,
    backgroundImage: !withAltAttribute ? backgroundImage : undefined,
    height:
      isAbsoluteView && view !== ModelView.SearchFormViewWithBottomOverlap && !isBEXSearchOptimizationForDesktopVariant
        ? "100%"
        : undefined,
    width:
      isAbsoluteView && view !== ModelView.SearchFormViewWithBottomOverlap && !isBEXSearchOptimizationForDesktopVariant
        ? "100%"
        : undefined,
  };

  const isDefault = context?.site?.brand === "expedia" || context?.site?.brand === "hotels" ? false : true;
  const defaultPosition = !withAltAttribute && isAttributionTextExist ? "relative" : undefined;

  return (
    <>
      {isDefault ? (
        <>
          <div
            className={mainClassNames}
            id={id}
            data-fm={fmId}
            data-fm-title-id={fmTitleId}
            style={imgStyle}
            title={heroTitle()}
          >
            {withAltAttribute ? (
              <>
                <BlossomImage
                  alt={selectedHeroImage.mediaAlt}
                  src={imageSource}
                  additionalProps={{ "aria-hidden": "true" }}
                />
                {backgroundImage ? <div className="uitk-image-media" style={imgStyle} /> : null}
              </>
            ) : null}
          </div>
          <AtrributionText textAlignment="left" />
        </>
      ) : (
        <EGDSSpacing margin={{ blockend: attributionTextOnTop ? "sixteen" : undefined }}>
          <div
            className={mainClassNames}
            id={id}
            data-fm={fmId}
            data-fm-title-id={fmTitleId}
            style={{
              ...imgStyle,
              ...{
                position: isAbsoluteView || isBEXSearchOptimizationForDesktopVariant ? "absolute" : defaultPosition,
              },
            }}
            title={heroTitle()}
          >
            {isAttributionTextExist && attributionTextOnTop && (
              <AtrributionText textAlignment="right" padding={false} />
            )}
            {withAltAttribute ? (
              <>
                <BlossomImage
                  alt={selectedHeroImage.mediaAlt}
                  src={imageSource}
                  additionalProps={{ "aria-hidden": "true" }}
                />
                {backgroundImage ? <div className="uitk-image-media" style={imgStyle} /> : null}
              </>
            ) : null}
            {isAttributionTextExist && !attributionTextOnTop ? (
              <EGDSScrim type={EGDSScrimType.BOTTOM}>
                <AtrributionText textAlignment="right" padding={false} />
              </EGDSScrim>
            ) : null}
          </div>
        </EGDSSpacing>
      )}
    </>
  );
});
