import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

type ImageGalleryMetaTag = {
  imageGalleryMetaTagType: string;
  imageGalleryMetaTagValue: string;
};

type ImageTracking = {
  schemaName: string;
  messageContent: {
    imageGalleryMetaTagList: ImageGalleryMetaTag[];
  };
};

type ShoppingGlobal = {
  schemaName: string;
  messageContent: {
    gaia: {
      stringOrigin: string;
    };
  };
};

type MicroMessage = (ImageTracking | ShoppingGlobal)[];

export const destinationWizardImpressionMicroMessage = (
  flexContext: ExtendedContextStore,
  gaiaId: string,
  imageId?: string,
  algorithmId?: string
) => {
  const microMessage: MicroMessage = [
    {
      schemaName: "shoppingGlobal",
      messageContent: {
        gaia: {
          stringOrigin:
            String(flexContext.searchContext.location?.id) ||
            String(flexContext.searchContext.destination?.id) ||
            String(gaiaId),
        },
      },
    },
  ];

  if (imageId || algorithmId || gaiaId) {
    const imageTrackingObj: ImageTracking = {
      schemaName: "imageTracking",
      messageContent: {
        imageGalleryMetaTagList: [],
      },
    };
    if (algorithmId) {
      imageTrackingObj.messageContent.imageGalleryMetaTagList.push({
        imageGalleryMetaTagType: "algorithm_id",
        imageGalleryMetaTagValue: algorithmId,
      });
    }
    if (imageId) {
      imageTrackingObj.messageContent.imageGalleryMetaTagList.push({
        imageGalleryMetaTagType: "image_id",
        imageGalleryMetaTagValue: imageId,
      });
    }
    if (gaiaId) {
      imageTrackingObj.messageContent.imageGalleryMetaTagList.push({
        imageGalleryMetaTagType: "original_gaia_id",
        imageGalleryMetaTagValue: gaiaId,
      });
    }

    microMessage.push(imageTrackingObj);
  }

  return microMessage;
};
